import * as React from 'react';

import { graphql } from 'gatsby';
import Styled from 'styled-components/macro';

import Content from 'components/layout/Content/Content';
import Layout from 'components/layout/Layout';
import YoutubeEmbed from 'components/layout/YoutubeEmbed/YoutubeEmbed';
import Meta from 'components/meta';
import HomeAutodesigner from 'components/pages/home/HomeAutodesigner';
import HomeAutodesignerLeft from 'components/pages/home/HomeAutodesignerLeft';
import HomeExamples from 'components/pages/home/HomeExamples';
import HomeFeatured from 'components/pages/home/HomeFeatured';
import HomeWireframe from 'components/pages/home/HomeWireframe';
import Shoutout from 'components/pages/signup/Shoutout';
import HeroBigTextBannerWithVideo from 'components/pages/solutions/HeroBigTextBannerWithVideo';
import SectionLeftOverGreyBackground from 'components/pages/solutions/SectionLeftOverGreyBackground';
import SectionWithLeftImage from 'components/pages/solutions/SectionWithLeftImage';
import SectionWithRightImage from 'components/pages/solutions/SectionWithRightImage';
import SectionWithVerticalImage from 'components/pages/solutions/SectionWithVerticalImage';
import constants from 'constants/index';

const ContentExtended = Styled(Content)`
  margin-top: 6rem;
`;

const Home = ({
  data,
}) => (
  <Layout>
    <Meta
      title="Uizard For Product Managers and Project Managers | Uizard"
      description="The best design tool for product managers, project managers, and product owners."
      url="/solutions/product-managers/"
    />

    <HeroBigTextBannerWithVideo
      tagline="Product Managers"
      headline="Visualize ideas at lightning speed"
      description="Create visuals in an instant and move your projects forward faster than ever before with the power of AI-assisted design."
      imageA={data.protoUserJourneyBig.childImageSharp}
      imageB={data.protoUserJourneySmall.childImageSharp}
      imageAlt="a user designing a screen in uizard for a mobile app"
      link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
      cta="Sign up for free"
      tagAsTitle
      videoName="uizard-prototype-cover"
    />

    <HomeAutodesignerLeft
      headline="Generate mockups with Autodesigner"
      description="From idea to clickable, interactive prototype in seconds. Generate product mockups from text prompts with the power of generative AI."
      description2="Gone are the days of waiting weeks for design resources... take control of your workflow like never before."
      imageA={data.autodesignerImageA.childImageSharp}
      imageB={data.autodesignerImageB.childImageSharp}
      imageAlt="turn text prompts into UI designs with AI"
      videoName="uizard-autodesigner-light"
      link="/autodesigner/"
    />

    <HomeAutodesigner
      headline="Convert screenshots into editable designs"
      description="Upload screenshots of apps and websites and transform them into editable mockups."
      description2="It's never been easier to update and iterate on established designs. Why spend weeks waiting for a mockup when you can convert a screenshot in seconds?"
      imageA={data.hificonversionImageA.childImageSharp}
      imageB={data.hificonversionImageB.childImageSharp}
      imageAlt="convert screen shot into editable UI mockup"
      videoName="uizard-scan-screenshot"
      link="/screenshot/"
      videoH={460}
    />

    <HomeWireframe
      headline="From low to high fidelity with a click"
      description="Tailor your design to your audience. Use Uizard's Wireframe Mode to instantly switch between low and high fidelity.
        Whether you're sharing your mockup with a design team or with external stakeholders, Uizard's got you covered."
      imageAlt="video showing how to turn wireframe mode in Uizard to render designs in low-fidelity or as high-fidelity mockups."
      imageA={data.wireframesToMockupsBig.childImageSharp}
      imageB={data.wireframesToMockupsSmall.childImageSharp}
      videoName="uizard-wireframe-high-fi-mode"
      link="/wireframing/"
      videoW={580}
    />

    <YoutubeEmbed
      videoW={1220}
      videoH={686}
      url="https://www.youtube.com/embed/KiOwTJ6YoI0?si=41B16bnfzMQhL14T"
      lightMode
    />

    <SectionWithRightImage
      headline="Working as a team; simplified"
      description="With Uizard's user-friendly interface and smart sharing options, you can easily add new team members to your project, share your designs with view only privileges, and encourage key team members to contribute with the commenting tool."
      imageA={data.projectManagersWorkingAsTeamBig.childImageSharp}
      imageB={data.projectManagersWorkingAsTeamSmall.childImageSharp}
      imageAlt="Overview of Uizard's collaborative features"
    />

    <SectionWithLeftImage
      headline="Enterprise: pure productivity"
      description="Assign organization-wide roles and permissions. Share themes, fonts, styles, and components. Boost productivity across projects quickly to streamline your ways of working. Uizard Enterprise really is the complete package for product owners."
      imageA={data.projectManagersEnterpriseBig.childImageSharp}
      imageB={data.projectManagersEnterpriseSmall.childImageSharp}
      imageAlt="Screen UI design components designed in Uizard"
      link="/enterprise/"
    />

    <SectionWithVerticalImage
      headline="Sketch to wireframe"
      description="Your design might start with pen and paper, or it could  be jotted down on a notepad, or it may even start with you and your team huddled round a whiteboard. Introducing Uizard's <a:https://uizard.io/design-assistant/>AI design tool</a>: sketch to wireframe... digitalize your design in seconds."
      imageA={data.projectManagersSketchToWireframeBig.childImageSharp}
      imageB={data.projectManagersSketchToWireframeSmall.childImageSharp}
      imageAlt="An image highlighting different steps of screen design in Uizard"
    />

    <SectionLeftOverGreyBackground
      headline="Expedite with Uizard's UI design templates"
      description="No time start from scratch with your app or web page design? No problem. With Uizard's range of <a:https://uizard.io/templates/>UI templates</a>, you can select the design that most suits you, update to add your own unique twist, and share with your team rapidly."
      imageA={data.projectManagersDesignTemplatesBig.childImageSharp}
      imageB={data.projectManagersDesignTemplatesSmall.childImageSharp}
      imageAlt="Visual showcasing screens from Uizard templates"
      link="/templates/"
    />

    <HomeFeatured />
    <HomeExamples />

    <ContentExtended>
      <Shoutout
        headline="Test your ideas"
        description="Bring your idea to life today"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    wireframesToMockupsBig: file(
      relativePath: { eq: "wireframing/WireframesToMockups_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsSmall: file(
      relativePath: { eq: "wireframing/WireframesToMockups_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoUserJourneyBig: file(
      relativePath: { eq: "prototyping/Proto_UserJourney_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1136, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoUserJourneySmall: file(
      relativePath: { eq: "prototyping/Proto_UserJourney_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 568, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageA: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageB: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessBig: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1116, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessSmall: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 559, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageA: file(
      relativePath: { eq: "ai/HifiConversion-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageB: file(
      relativePath: { eq: "ai/HifiConversion-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersSketchToWireframeBig: file(
      relativePath: {
        eq: "project-managers/ProjectManagers_SketchToWireframe_2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersSketchToWireframeSmall: file(
      relativePath: {
        eq: "project-managers/ProjectManagers_SketchToWireframe_1x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1070, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersDesignTemplatesBig: file(
      relativePath: {
        eq: "mockups/Mockups_DesignTemplates2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 854, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersDesignTemplatesSmall: file(
      relativePath: {
        eq: "mockups/Mockups_DesignTemplates1x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 427, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersWorkingAsTeamBig: file(
      relativePath: {
        eq: "project-managers/ProjectManagers_WorkingAsTeam_2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1152, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersWorkingAsTeamSmall: file(
      relativePath: {
        eq: "project-managers/ProjectManagers_WorkingAsTeam_1x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersEnterpriseBig: file(
      relativePath: { eq: "project-managers/ProjectManagers_Enterprise_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1116, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersEnterpriseSmall: file(
      relativePath: { eq: "project-managers/ProjectManagers_Enterprise_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 558, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
